import React, { useMemo } from 'react';
import dateIcon from '../../images/blog_date.svg';
import userIcon from '../../images/blog_user.svg';
import Img from '../../utils/OptimizedImage';
import getRelativeDate from '../../utils/getRelativeDate';

const AuthorInfo = ({ authorName, updatedAt }) => {
  // Intercom dates are missing trailing 0s, so add them
  const adjustedUpdatedDate = useMemo(
    () =>
      parseInt(`${updatedAt}${'0'.repeat(13 - String(updatedAt).length)}`, 10),
    [updatedAt]
  );
  const relativeUpdatedDate = useMemo(
    () => getRelativeDate(new Date(adjustedUpdatedDate), 'days'),
    [adjustedUpdatedDate]
  );

  return (
    <div className="author-info">
      {authorName && (
        <p className="item">
          <Img src={userIcon} alt="Author" />
          <span>{authorName}</span>
        </p>
      )}
      <p className="item">
        <Img src={dateIcon} alt="Updated Date" />
        <span>{`Updated ${relativeUpdatedDate}`}</span>
      </p>
    </div>
  );
};

export default AuthorInfo;
