import styled from 'styled-components';
import { colors } from './colors';
import { font } from './typography';

export const Longform = styled.div`
  ${font('text', 'md', '400')}
  color: ${colors.gray[600]};

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    color: ${colors.gray[900]};
  }

  h2 {
    ${font('display', 'md', '700')}
    margin-top: 40px;
    margin-bottom: 16px;
  }

  h3 {
    ${font('display', 'sm', '700')}
    margin-top: 30px;
    margin-bottom: 16px;
  }

  h4 {
    ${font('text', 'lg', '700')}
    margin-top: 20px;
    margin-bottom: 20px;
  }

  b,
  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    padding-left: 40px;
    margin-bottom: 1rem;

    > li {
      margin-bottom: 1rem;

      &::marker {
        color: ${colors.gray[900]};
      }

      > p:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin-bottom: 1rem;
    }
  }

  table {
    margin-bottom: 1rem;

    td {
      border: 1px solid ${colors.gray[300]};
      padding: 1rem;
      ${font('text', 'sm', '400')}
      vertical-align: top;

      > *:last-child {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul,
      ol {
        padding-left: 14px;

        &:has(+ ul, +ol) {
          margin-bottom: 0.5rem;
        }

        li {
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  superscript,
  subscript {
    font-size: 75%;
    line-height: 0;
  }

  superscript {
    vertical-align: super;
  }

  subscript {
    vertical-align: sub;
  }

  hr {
    margin: 1.5rem 0;
  }

  figure {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .gatsby-image-wrapper {
      margin: 0 auto;
    }

    figcaption {
      padding: 0 40px;
      margin-top: 1rem;
      font-size: 16px;
      font-style: italic;
      text-align: center;
    }
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 768px;
  }

  .gatsby-image-wrapper {
      max-width: 100%;
      max-height: 768px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

  .video {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16/9;
    display: block;
    margin-bottom: 1rem;
    outline: none;
    border: none;
  }
`;
