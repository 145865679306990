const formatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: 'auto',
});

const divisions = [
  { amount: 60, name: 'seconds', overflowValue: 'over a minute' },
  { amount: 60, name: 'minutes', overflowValue: 'over an hour' },
  { amount: 24, name: 'hours', overflowValue: 'over a day' },
  { amount: 7, name: 'days', overflowValue: 'over a week' },
  { amount: 4.34524, name: 'weeks', overflowValue: 'over a month' },
  { amount: 12, name: 'months', overflowValue: 'over a year' },
  { amount: Number.POSITIVE_INFINITY, name: 'years', overflowValue: '' },
];

const getRelativeDate = (date, maxPrecision = 'years') => {
  let duration = (date - new Date()) / 1000;

  for (let i = 0; i < divisions.length; i++) {
    const division = divisions[i];

    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }

    if (maxPrecision === division.name) {
      return `${division.overflowValue} ${duration > 0 ? 'from now' : 'ago'}`;
    }

    duration /= division.amount;
  }
};

export default getRelativeDate;
