import styled from 'styled-components';
import { font } from '../../atoms/typography';

export default styled.div`
  margin-bottom: ${props => props.marginBottom};

  .breadcrumb {
    max-width: 100%;
    flex-wrap: nowrap;
    white-space: nowrap;
    background: inherit;
    padding: 0;
    margin-bottom: 32px;
    ${font('text', 'sm', '400')}

    &-item {
      &::before {
        font-weight: 500;
        color: ${props => (props.isDark ? '#fff' : 'var(subtle-text-color)')};
      }

      &:hover {
        a {
          color: ${props => (props.isDark ? '#fff' : '#0015ca')};
          text-decoration: none;
        }
      }

      a {
        color: ${props => props.isDark && '#fff'};
        font-weight: ${props => (props.isDark ? '400' : '500')};
      }

      + .breadcrumb-item {
        &::before {
          float: none;
        }
      }
    }

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .active {
      max-width: 600px;
      color: ${props => (props.isDark ? '#fff' : 'var(--text-color)')};
      font-weight: ${props => (props.isDark ? '400' : '500')};
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .disabled {
      pointer-events: none;

      a {
        color: ${props => (props.isDark ? '#fff' : 'var(--text-color)')};
      }
    }

    .origin-item {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: ${props => (props.isDark ? '#fff' : 'var(--primary-color)')};
    }
  }
`;
