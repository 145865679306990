import styled from 'styled-components';
import { font } from '../../styles/atoms/typography';

export const ContentWrapper = styled.div`
  padding-top: 165px;

  @media (max-width: 768px) {
    padding-top: 30px;
  }

  .title {
    margin: 32px 0;
  }

  .side-bar {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .author-info {
    display: flex;
    gap: 36px;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 1199px) {
      gap: 20px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 11px;
      margin: 0;
    }

    img {
      vertical-align: middle;
      max-width: 18px;
    }

    span {
      ${font('text', 'sm', '700')}
      color: var(--text-color);
      line-height: 1.2;
      display: block;
    }

    p {
      display: flex;

      @media (max-width: 768px) {
        display: block;
        margin-bottom: 6px;
      }
    }
  }

  .content {
    margin: 48px 0;

    .intercom-container {
      margin-bottom: 1rem;
    }

    p:empty {
      display: none;
    }
  }
`;
