import React, { useMemo } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import BreadcrumbStyles from '../../styles/Components/Breadcrumbs/BreadcrumbStyles';
import Link from '../../utils/Link';

const Breadcrumbs = ({ isDark, marginBottom, breadcrumb }) => {
  const links = useMemo(
    () =>
      breadcrumb &&
      breadcrumb.length > 0 &&
      breadcrumb.map((path, index) => (
        <Breadcrumb.Item
          className={path.truncate ? 'truncate' : ''}
          key={path.label}
          active={index === breadcrumb.length - 1}
          linkAs={Link}
          linkProps={{ to: path.link }}
        >
          <span className={breadcrumb.length === 1 ? 'origin-item' : ''}>
            {path.label}
          </span>
        </Breadcrumb.Item>
      )),
    [breadcrumb]
  );

  return (
    <BreadcrumbStyles isDark={isDark} marginBottom={marginBottom}>
      <Breadcrumb>{links}</Breadcrumb>
    </BreadcrumbStyles>
  );
};

export default Breadcrumbs;
