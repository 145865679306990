import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { breakpoints } from '../../styles/atoms/breakpoints';
import { Longform } from '../../styles/atoms/longform';
import { ContentWrapper } from '../../templates/styles/helpCenterStyles';
import { toCamelCase } from '../../utils/helperFunctions';
import Breadcrumbs from '../Breadcrumbs';
import SidebarHelpCard from '../Cards/SidebarHelpCard';
import AuthorInfo from './AuthorInfo';

const HelpArticleContent = ({ articleAuthor, helpArticle }) => {
  const isTablet = !useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const { body, parent, description, title, updatedAt } = helpArticle;
  const { name: authorName } = articleAuthor;
  const breadcrumbs = [{ label: 'Shopmonkey', link: '/' }];

  // Build breadcrumbs
  breadcrumbs.push({ label: 'Help Center' || '', link: '/help' });

  if (parent?.internal?.type) {
    if (
      parent.internal.type === 'HelpCenterSection' &&
      parent.parent?.internal.type === 'HelpCenterCollection'
    ) {
      // Parent is a section, so get its parent collection
      breadcrumbs.push({
        label: parent.parent.name,
        link: `/help?collection=${toCamelCase(parent.parent.name)}#articles`,
        truncate: isTablet,
      });
    } else {
      // Parent is a collection
      breadcrumbs.push({
        label: parent.name,
        link: `/help?collection=${toCamelCase(parent.name)}#articles`,
        truncate: isTablet,
      });
    }
  }

  breadcrumbs.push({ label: title || '', link: '#' });

  return (
    <ContentWrapper>
      <Container>
        <Row>
          <Col lg={8}>
            <Breadcrumbs breadcrumb={breadcrumbs} />
            {title && <h1 className="title">{title}</h1>}
            <p>{description}</p>
            <AuthorInfo authorName={authorName} updatedAt={updatedAt} />
            <Longform
              className="content"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </Col>
          <Col lg={{ offset: 1, span: 3 }}>
            <SidebarHelpCard />
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  );
};

export default HelpArticleContent;
